import React from 'react';
import { collection, addDoc, getDocs, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { Song } from '../../types';
import { Music, Tag, Trash2, Edit, Plus } from 'lucide-react';
import toast from 'react-hot-toast';
import { useAuth } from '../../contexts/AuthContext';
import { normalizeFirestoreData, extractGenres } from '../../utils/songUtils';
import { Modal } from '../../components/Modal';
import { SearchInput } from '../../components/SearchInput';
import { validateSong } from '../../utils/validation';

export function AdminSongs() {
  const { user, isAdmin } = useAuth();
  const [songs, setSongs] = React.useState<Song[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [genres, setGenres] = React.useState<string[]>([]);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [editingSong, setEditingSong] = React.useState<Song | null>(null);
  const [searchQuery, setSearchQuery] = React.useState('');
  const [selectedGenre, setSelectedGenre] = React.useState<string | null>(null);
  const [formData, setFormData] = React.useState({
    title: '',
    artist: '',
    genre: ''
  });

  const fetchSongs = async () => {
    if (!user || !isAdmin) {
      setLoading(false);
      return;
    }

    try {
      const songsRef = collection(db, 'songs');
      const snapshot = await getDocs(songsRef);
      
      const songsList = snapshot.docs
        .map(doc => {
          const data = doc.data();
          const normalizedData = normalizeFirestoreData(data);
          return {
            id: doc.id,
            ...normalizedData
          } as Song;
        })
        .filter(song => song.title && song.artist)
        .sort((a, b) => a.title.localeCompare(b.title));

      const uniqueGenres = extractGenres(songsList);
      setGenres(uniqueGenres);
      setSongs(songsList);
    } catch (error) {
      console.error('Error fetching songs:', error);
      toast.error('Failed to load songs');
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchSongs();
  }, [user, isAdmin]);

  const handleOpenModal = (song?: Song) => {
    if (song) {
      setEditingSong(song);
      setFormData({
        title: song.title,
        artist: song.artist,
        genre: song.genre || ''
      });
    } else {
      setEditingSong(null);
      setFormData({
        title: '',
        artist: '',
        genre: ''
      });
    }
    setIsModalOpen(true);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const validation = validateSong(formData);
    if (!validation.isValid) {
      validation.errors.forEach(error => toast.error(error));
      return;
    }

    try {
      const songData = {
        title: formData.title.trim(),
        artist: formData.artist.trim(),
        genre: formData.genre.trim() || null
      };

      if (editingSong) {
        await updateDoc(doc(db, 'songs', editingSong.id), songData);
        toast.success('Song updated successfully');
      } else {
        await addDoc(collection(db, 'songs'), songData);
        toast.success('Song added successfully');
      }
      
      setIsModalOpen(false);
      fetchSongs();
    } catch (error) {
      console.error('Error saving song:', error);
      toast.error(editingSong ? 'Failed to update song' : 'Failed to add song');
    }
  };

  const handleDelete = async (songId: string) => {
    if (!window.confirm('Are you sure you want to delete this song?')) {
      return;
    }

    try {
      await deleteDoc(doc(db, 'songs', songId));
      toast.success('Song deleted successfully');
      fetchSongs();
    } catch (error) {
      console.error('Error deleting song:', error);
      toast.error('Failed to delete song');
    }
  };

  const filteredSongs = React.useMemo(() => {
    return songs.filter(song => {
      const matchesSearch = searchQuery.trim() === '' || 
        song.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        song.artist.toLowerCase().includes(searchQuery.toLowerCase());
      
      const matchesGenre = !selectedGenre || 
        (song.genre && song.genre.toLowerCase().split(/[,;|/]/).map(g => g.trim()).includes(selectedGenre.toLowerCase()));
      
      return matchesSearch && matchesGenre;
    });
  }, [songs, searchQuery, selectedGenre]);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-[50vh]">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-600"></div>
      </div>
    );
  }

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-gray-900">
          Manage Songs ({filteredSongs.length})
          {genres.length > 0 && <span className="text-gray-500 text-base ml-2">• {genres.length} genres</span>}
        </h1>
        <button
          onClick={() => handleOpenModal()}
          className="flex items-center px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700"
        >
          <Plus className="w-5 h-5 mr-2" />
          Add Song
        </button>
      </div>

      <div className="mb-6 space-y-4">
        <SearchInput
          value={searchQuery}
          onChange={setSearchQuery}
          placeholder="Search by song title or artist..."
        />

        {genres.length > 0 && (
          <div className="flex flex-wrap gap-2">
            <button
              onClick={() => setSelectedGenre(null)}
              className={`flex items-center px-3 py-1.5 rounded-full text-sm font-medium transition-colors ${
                selectedGenre === null
                  ? 'bg-indigo-100 text-indigo-800'
                  : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
              }`}
            >
              <Music className="w-4 h-4 mr-1" />
              All Genres
            </button>
            {genres.map((genre) => (
              <button
                key={genre}
                onClick={() => setSelectedGenre(genre === selectedGenre ? null : genre)}
                className={`flex items-center px-3 py-1.5 rounded-full text-sm font-medium transition-colors ${
                  selectedGenre === genre
                    ? 'bg-indigo-100 text-indigo-800'
                    : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                }`}
              >
                <Tag className="w-4 h-4 mr-1" />
                {genre}
              </button>
            ))}
          </div>
        )}
      </div>

      <div className="grid gap-4">
        {filteredSongs.map((song) => (
          <div key={song.id} className="bg-white p-4 rounded-lg shadow-md flex justify-between items-center">
            <div className="flex items-center">
              <Music className="w-5 h-5 text-indigo-600 mr-3" />
              <div>
                <h3 className="font-medium text-gray-900">{song.title}</h3>
                <div className="flex items-center text-sm text-gray-500 mt-1">
                  <span>{song.artist}</span>
                  {song.genre && (
                    <>
                      <span className="mx-2">•</span>
                      <div className="flex items-center">
                        <Tag className="w-4 h-4 mr-1" />
                        <span>{song.genre}</span>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="flex space-x-2">
              <button
                onClick={() => handleOpenModal(song)}
                className="p-2 text-gray-600 hover:text-indigo-600 hover:bg-indigo-50 rounded-full"
              >
                <Edit className="w-5 h-5" />
              </button>
              <button
                onClick={() => handleDelete(song.id)}
                className="p-2 text-gray-600 hover:text-red-600 hover:bg-red-50 rounded-full"
              >
                <Trash2 className="w-5 h-5" />
              </button>
            </div>
          </div>
        ))}

        {filteredSongs.length === 0 && (
          <div className="text-center py-12">
            <Music className="w-12 h-12 text-gray-400 mx-auto mb-4" />
            <h3 className="text-lg font-medium text-gray-900">No songs found</h3>
            <p className="text-gray-500">
              {searchQuery || selectedGenre ? 'Try different search terms or filters' : 'Add some songs to get started'}
            </p>
          </div>
        )}
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={editingSong ? 'Edit Song' : 'Add New Song'}
      >
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Song Title</label>
            <input
              type="text"
              value={formData.title}
              onChange={(e) => setFormData({ ...formData, title: e.target.value })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              placeholder="Enter song title"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Artist</label>
            <input
              type="text"
              value={formData.artist}
              onChange={(e) => setFormData({ ...formData, artist: e.target.value })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              placeholder="Enter artist name"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Genre (Optional)</label>
            <input
              type="text"
              value={formData.genre}
              onChange={(e) => setFormData({ ...formData, genre: e.target.value })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              placeholder="Enter genres (separate multiple with commas)"
            />
          </div>

          <div className="flex justify-end space-x-3 mt-6">
            <button
              type="button"
              onClick={() => setIsModalOpen(false)}
              className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 bg-white hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700"
            >
              {editingSong ? 'Update Song' : 'Add Song'}
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
}