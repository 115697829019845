import React, { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Calendar, Plus, ChevronDown, ExternalLink, Edit, Trash2, Copy } from 'lucide-react';
import { collection, addDoc, getDocs, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { Event } from '../../types';
import toast from 'react-hot-toast';
import { ensureValidDate, formatDateTimeForInput, formatEventDate } from '../../utils/dateFormat';
import { validateEvent } from '../../utils/validation';
import { Modal } from '../../components/Modal';
import { EventCalendar } from '../../components/EventCalendar';
import { EventForm } from '../../components/admin/EventForm';
import { ShareEvent } from '../../components/ShareEvent';
import { parseISO, isSameDay, compareAsc, isValid } from 'date-fns';

export function AdminEvents() {
  const [events, setEvents] = useState<Event[]>([]);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingEvent, setEditingEvent] = useState<Event | null>(null);
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [showCalendar, setShowCalendar] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    date: '',
    location: '',
    description: '',
    venmoUsername: '',
    suggestedTipAmount: ''
  });

  const fetchEvents = async () => {
    try {
      const eventsRef = collection(db, 'events');
      const snapshot = await getDocs(eventsRef);
      const eventsList = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as Event[];

      // Sort events chronologically
      const sortedEvents = eventsList.sort((a, b) => {
        const dateA = parseISO(a.date);
        const dateB = parseISO(b.date);
        
        if (!isValid(dateA) || !isValid(dateB)) return 0;
        return compareAsc(dateA, dateB);
      });

      setEvents(sortedEvents);
    } catch (error) {
      console.error('Error fetching events:', error);
      toast.error('Failed to load events');
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchEvents();
  }, []);

  const handleOpenModal = (event?: Event, isDuplicating: boolean = false) => {
    if (event) {
      setEditingEvent(isDuplicating ? null : event);
      setFormData({
        name: isDuplicating ? `${event.name} (Copy)` : event.name,
        date: formatDateTimeForInput(event.date),
        location: event.location,
        description: event.description || '',
        venmoUsername: event.venmoUsername || '',
        suggestedTipAmount: event.suggestedTipAmount?.toString() || ''
      });
    } else {
      setEditingEvent(null);
      setFormData({
        name: '',
        date: '',
        location: '',
        description: '',
        venmoUsername: '',
        suggestedTipAmount: ''
      });
    }
    setIsModalOpen(true);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const validation = validateEvent({
      name: formData.name,
      date: formData.date,
      location: formData.location
    });

    if (!validation.isValid) {
      validation.errors.forEach(error => toast.error(error));
      return;
    }

    try {
      const eventData = {
        name: formData.name.trim(),
        date: ensureValidDate(formData.date),
        location: formData.location.trim(),
        description: formData.description.trim() || null,
        venmoUsername: formData.venmoUsername.trim() || null,
        suggestedTipAmount: formData.suggestedTipAmount ? Number(formData.suggestedTipAmount) : null
      };

      if (editingEvent) {
        await updateDoc(doc(db, 'events', editingEvent.id), eventData);
        toast.success('Event updated successfully');
      } else {
        await addDoc(collection(db, 'events'), eventData);
        toast.success('Event added successfully');
      }
      
      setIsModalOpen(false);
      fetchEvents();
    } catch (error) {
      console.error('Error saving event:', error);
      toast.error(editingEvent ? 'Failed to update event' : 'Failed to add event');
    }
  };

  const handleDelete = async (eventId: string) => {
    if (!window.confirm('Are you sure you want to delete this event?')) {
      return;
    }

    try {
      await deleteDoc(doc(db, 'events', eventId));
      toast.success('Event deleted successfully');
      fetchEvents();
    } catch (error) {
      console.error('Error deleting event:', error);
      toast.error('Failed to delete event');
    }
  };

  const filteredEvents = useMemo(() => {
    if (!selectedDate) return events;
    return events.filter(event => {
      try {
        return isSameDay(parseISO(event.date), selectedDate);
      } catch (error) {
        console.error('Error filtering event:', error);
        return false;
      }
    });
  }, [events, selectedDate]);

  const groupedEvents = useMemo(() => {
    const groups: { [key: string]: Event[] } = {};
    
    filteredEvents.forEach(event => {
      try {
        const date = formatEventDate(event.date);
        if (!groups[date]) {
          groups[date] = [];
        }
        groups[date].push(event);
      } catch (error) {
        console.error('Error grouping event:', error);
      }
    });

    // Sort dates chronologically
    return Object.entries(groups)
      .sort(([dateA], [dateB]) => {
        const parsedA = parseISO(filteredEvents.find(e => formatEventDate(e.date) === dateA)?.date || '');
        const parsedB = parseISO(filteredEvents.find(e => formatEventDate(e.date) === dateB)?.date || '');
        return compareAsc(parsedA, parsedB);
      })
      .map(([date, events]) => ({
        date,
        events: events.sort((a, b) => compareAsc(parseISO(a.date), parseISO(b.date)))
      }));
  }, [filteredEvents]);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-[50vh]">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-600"></div>
      </div>
    );
  }

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-gray-900">Manage Events</h1>
        <div className="flex space-x-2">
          <button
            onClick={() => setShowCalendar(!showCalendar)}
            className="flex items-center px-4 py-2 bg-white border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50"
          >
            <Calendar className="w-5 h-5 mr-2 text-gray-500" />
            Calendar View
            <ChevronDown className={`w-4 h-4 ml-2 transition-transform ${showCalendar ? 'rotate-180' : ''}`} />
          </button>
          <button
            onClick={() => handleOpenModal()}
            className="flex items-center px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700"
          >
            <Plus className="w-5 h-5 mr-2" />
            Add Event
          </button>
        </div>
      </div>

      {showCalendar && (
        <div className="mb-6">
          <EventCalendar
            events={events}
            selectedDate={selectedDate}
            onSelect={(date) => setSelectedDate(date)}
          />
        </div>
      )}

      <div className="space-y-8">
        {groupedEvents.map(({ date, events }) => (
          <div key={date}>
            <h2 className="text-lg font-semibold text-gray-900 mb-4">{date}</h2>
            <div className="space-y-4">
              {events.map((event) => (
                <div key={event.id} className="bg-white p-6 rounded-lg shadow-md">
                  <div className="flex justify-between items-start">
                    <div className="flex-1">
                      <div className="flex items-center justify-between">
                        <Link 
                          to={`/events/${event.id}`}
                          className="text-xl font-semibold text-gray-900 hover:text-indigo-600 flex items-center gap-2"
                        >
                          {event.name}
                          <ExternalLink className="w-4 h-4" />
                        </Link>
                        <ShareEvent eventId={event.id} eventName={event.name} />
                      </div>
                      <div className="mt-2 space-y-2">
                        <div className="flex items-center text-gray-600">
                          <Calendar className="w-5 h-5 mr-2" />
                          <span>{formatEventDate(event.date)}</span>
                        </div>
                        {event.description && (
                          <p className="text-gray-600 mt-2">{event.description}</p>
                        )}
                      </div>
                    </div>
                    <div className="flex space-x-2 ml-4">
                      <button
                        onClick={() => handleOpenModal(event, true)}
                        className="p-2 text-gray-600 hover:text-indigo-600 hover:bg-indigo-50 rounded-full"
                        title="Duplicate Event"
                      >
                        <Copy className="w-5 h-5" />
                      </button>
                      <button
                        onClick={() => handleOpenModal(event)}
                        className="p-2 text-gray-600 hover:text-indigo-600 hover:bg-indigo-50 rounded-full"
                        title="Edit Event"
                      >
                        <Edit className="w-5 h-5" />
                      </button>
                      <button
                        onClick={() => handleDelete(event.id)}
                        className="p-2 text-gray-600 hover:text-red-600 hover:bg-red-50 rounded-full"
                        title="Delete Event"
                      >
                        <Trash2 className="w-5 h-5" />
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
        {groupedEvents.length === 0 && (
          <div className="text-center py-12">
            <Calendar className="w-12 h-12 text-gray-400 mx-auto mb-4" />
            <h3 className="text-lg font-medium text-gray-900">No events found</h3>
            <p className="text-gray-500">Add some events to get started.</p>
          </div>
        )}
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={editingEvent ? 'Edit Event' : 'Add New Event'}
      >
        <EventForm
          formData={formData}
          onSubmit={handleSubmit}
          onChange={(data) => setFormData({ ...formData, ...data })}
          isEditing={!!editingEvent}
        />
      </Modal>
    </div>
  );
}