import React from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { Event } from '../types';
import { DollarSign, Wallet, CreditCard } from 'lucide-react';
import toast from 'react-hot-toast';

export function TipPage() {
  const { id } = useParams<{ id: string }>();
  const [event, setEvent] = React.useState<Event | null>(null);
  const [loading, setLoading] = React.useState(true);
  const [selectedAmount, setSelectedAmount] = React.useState<number | ''>('');

  React.useEffect(() => {
    async function fetchEvent() {
      if (!id) return;
      try {
        const eventDoc = await getDoc(doc(db, 'events', id));
        if (eventDoc.exists()) {
          setEvent({ id: eventDoc.id, ...eventDoc.data() } as Event);
          if (eventDoc.data().suggestedTipAmount) {
            setSelectedAmount(eventDoc.data().suggestedTipAmount);
          }
        }
      } catch (error) {
        console.error('Error fetching event:', error);
        toast.error('Failed to load event details');
      } finally {
        setLoading(false);
      }
    }

    fetchEvent();
  }, [id]);

  const handleVenmoTip = () => {
    if (!event?.venmoUsername || !selectedAmount) return;
    
    const venmoUrl = `https://venmo.com/${event.venmoUsername}?txn=pay&amount=${selectedAmount}&note=Piano Performance Tip`;
    window.open(venmoUrl, '_blank');
    toast.success('Opening Venmo...');
  };

  const handleCashTip = () => {
    toast.success('Please visit the tip jar at the piano!');
  };

  const tipAmounts = [1, 5, 10, 20];

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-[50vh]">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-600"></div>
      </div>
    );
  }

  if (!event) {
    return (
      <div className="text-center py-12">
        <h2 className="text-2xl font-bold text-gray-900">Event not found</h2>
      </div>
    );
  }

  return (
    <div className="max-w-md mx-auto px-4 py-8">
      <div className="text-center mb-8">
        <DollarSign className="w-16 h-16 text-indigo-600 mx-auto mb-4" />
        <h1 className="text-3xl font-bold text-gray-900 mb-2">Send Brian a Tip</h1>
        <p className="text-gray-600">Show your appreciation for the music!</p>
      </div>

      <div className="bg-white rounded-lg shadow-md p-6 mb-6">
        <h2 className="text-lg font-semibold text-gray-900 mb-4">Select Amount</h2>
        <div className="grid grid-cols-2 gap-3 mb-4">
          {tipAmounts.map((amount) => (
            <button
              key={amount}
              onClick={() => setSelectedAmount(amount)}
              className={`py-3 px-4 rounded-lg border text-lg font-medium transition-colors
                ${selectedAmount === amount
                  ? 'border-indigo-600 bg-indigo-50 text-indigo-700'
                  : 'border-gray-300 text-gray-700 hover:border-indigo-300'
                }`}
            >
              ${amount}
            </button>
          ))}
        </div>
        <div className="relative rounded-md shadow-sm mb-6">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <DollarSign className="h-5 w-5 text-gray-400" />
          </div>
          <input
            type="number"
            min="0"
            step="1"
            value={selectedAmount}
            onChange={(e) => setSelectedAmount(Number(e.target.value))}
            className="pl-10 block w-full rounded-md border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
            placeholder="Enter custom amount"
          />
        </div>

        <div className="space-y-3">
          {event.venmoUsername && (
            <button
              onClick={handleVenmoTip}
              disabled={!selectedAmount}
              className="w-full flex items-center justify-center px-4 py-3 border border-transparent rounded-lg text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
            >
              <Wallet className="w-5 h-5 mr-2" />
              Tip with Venmo
            </button>
          )}
          <button
            onClick={handleCashTip}
            className="w-full flex items-center justify-center px-4 py-3 border border-gray-300 rounded-lg text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <CreditCard className="w-5 h-5 mr-2" />
            Cash Tip
          </button>
        </div>
      </div>

      <p className="text-center text-sm text-gray-500">
        100% of tips go directly to the performer
      </p>
    </div>
  );
}