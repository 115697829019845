import React from 'react';
import { Routes, Route, Link, useNavigate, Navigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from '../lib/firebase';
import { AdminEvents } from './admin/AdminEvents';
import { AdminSongs } from './admin/AdminSongs';
import { AdminRequests } from './admin/AdminRequests';
import { Calendar, Music, ListMusic, LogOut } from 'lucide-react';
import toast from 'react-hot-toast';

export function AdminDashboard() {
  const navigate = useNavigate();

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      toast.success('Logged out successfully');
      navigate('/admin/login');
    } catch (error) {
      console.error('Logout error:', error);
      toast.error('Failed to log out');
    }
  };

  return (
    <div className="admin-layout flex -mt-8">
      {/* Sidebar */}
      <div className="admin-sidebar w-64 min-h-screen">
        <div className="p-6 border-b border-gray-200">
          <h2 className="text-xl font-bold text-gray-800">Admin Dashboard</h2>
        </div>
        <nav className="mt-6">
          <Link
            to="/admin/events"
            className="flex items-center px-6 py-3 text-gray-700 hover:bg-indigo-50 hover:text-indigo-600 transition-colors"
          >
            <Calendar className="w-5 h-5 mr-3" />
            Events
          </Link>
          <Link
            to="/admin/songs"
            className="flex items-center px-6 py-3 text-gray-700 hover:bg-indigo-50 hover:text-indigo-600 transition-colors"
          >
            <Music className="w-5 h-5 mr-3" />
            Songs
          </Link>
          <Link
            to="/admin/requests"
            className="flex items-center px-6 py-3 text-gray-700 hover:bg-indigo-50 hover:text-indigo-600 transition-colors"
          >
            <ListMusic className="w-5 h-5 mr-3" />
            Requests
          </Link>
          <button
            onClick={handleSignOut}
            className="flex items-center w-full px-6 py-3 text-gray-700 hover:bg-red-50 hover:text-red-600 transition-colors"
          >
            <LogOut className="w-5 h-5 mr-3" />
            Sign Out
          </button>
        </nav>
      </div>

      {/* Main Content */}
      <div className="admin-content flex-1 p-8">
        <Routes>
          <Route path="events" element={<AdminEvents />} />
          <Route path="songs" element={<AdminSongs />} />
          <Route path="requests" element={<AdminRequests />} />
          <Route path="/" element={<Navigate to="/admin/events" replace />} />
        </Routes>
      </div>
    </div>
  );
}