import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Lock, AlertCircle } from 'lucide-react';
import toast from 'react-hot-toast';
import { useAuth } from '../contexts/AuthContext';

const MAX_ATTEMPTS = 5;
const LOCKOUT_DURATION = 15 * 60 * 1000; // 15 minutes in milliseconds

export function AdminLogin() {
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);
  const [attempts, setAttempts] = React.useState(0);
  const [lockoutTime, setLockoutTime] = React.useState<number | null>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { login, isAdmin } = useAuth();

  React.useEffect(() => {
    if (isAdmin) {
      navigate('/admin');
    }

    // Check for existing lockout
    const storedLockout = localStorage.getItem('adminLoginLockout');
    if (storedLockout) {
      const lockoutData = JSON.parse(storedLockout);
      const now = Date.now();
      if (now < lockoutData.expires) {
        setLockoutTime(lockoutData.expires);
        setAttempts(lockoutData.attempts);
      } else {
        localStorage.removeItem('adminLoginLockout');
      }
    }
  }, [isAdmin, navigate]);

  React.useEffect(() => {
    let timer: number;
    if (lockoutTime) {
      timer = window.setInterval(() => {
        const now = Date.now();
        if (now >= lockoutTime) {
          setLockoutTime(null);
          setAttempts(0);
          localStorage.removeItem('adminLoginLockout');
        }
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [lockoutTime]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);

    // Check if locked out
    if (lockoutTime && Date.now() < lockoutTime) {
      const remainingMinutes = Math.ceil((lockoutTime - Date.now()) / 60000);
      setError(`Too many failed attempts. Please try again in ${remainingMinutes} minutes.`);
      return;
    }

    // Basic validation
    if (!email.trim() || !password.trim()) {
      setError('Please enter both email and password');
      return;
    }

    // Email format validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError('Please enter a valid email address');
      return;
    }

    setLoading(true);

    try {
      await login(email, password);
      const from = location.state?.from?.pathname || '/admin';
      navigate(from, { replace: true });
      
      // Reset attempts on successful login
      setAttempts(0);
      localStorage.removeItem('adminLoginLockout');
    } catch (error: any) {
      console.error('Login error:', error);
      
      // Increment attempts
      const newAttempts = attempts + 1;
      setAttempts(newAttempts);

      // Check if should lockout
      if (newAttempts >= MAX_ATTEMPTS) {
        const lockoutExpires = Date.now() + LOCKOUT_DURATION;
        setLockoutTime(lockoutExpires);
        localStorage.setItem('adminLoginLockout', JSON.stringify({
          attempts: newAttempts,
          expires: lockoutExpires
        }));
        setError(`Too many failed attempts. Please try again in 15 minutes.`);
      } else {
        let errorMessage = 'Failed to log in. Please try again.';
        
        if (error.message === 'User is not an admin') {
          errorMessage = 'This account does not have admin access';
        } else if (error.code === 'auth/invalid-credential') {
          errorMessage = 'Invalid email or password';
        } else if (error.code === 'auth/invalid-email') {
          errorMessage = 'Invalid email format';
        }
        
        setError(errorMessage);
        toast.error(errorMessage);
      }
    } finally {
      setLoading(false);
    }
  };

  const remainingAttempts = MAX_ATTEMPTS - attempts;

  return (
    <div className="min-h-[80vh] flex items-center justify-center">
      <div className="max-w-md w-full space-y-8 p-8 bg-white rounded-lg shadow-md">
        <div className="text-center">
          <Lock className="mx-auto h-12 w-12 text-indigo-600" />
          <h2 className="mt-6 text-3xl font-extrabold text-gray-900">Admin Login</h2>
        </div>

        {error && (
          <div className="bg-red-50 border-l-4 border-red-400 p-4 rounded">
            <div className="flex items-center">
              <AlertCircle className="h-5 w-5 text-red-400 mr-2" />
              <p className="text-sm text-red-700">{error}</p>
            </div>
          </div>
        )}

        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
          <div className="rounded-md shadow-sm space-y-4">
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                Email address
              </label>
              <input
                id="email"
                name="email"
                type="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="appearance-none rounded-lg relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                placeholder="admin@example.com"
                disabled={loading || !!lockoutTime}
              />
            </div>
            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-1">
                Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="appearance-none rounded-lg relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                placeholder="••••••••"
                disabled={loading || !!lockoutTime}
              />
            </div>
          </div>

          {!lockoutTime && remainingAttempts < MAX_ATTEMPTS && (
            <p className="text-sm text-amber-600">
              {remainingAttempts} login {remainingAttempts === 1 ? 'attempt' : 'attempts'} remaining
            </p>
          )}

          <button
            type="submit"
            disabled={loading || !!lockoutTime}
            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
          >
            {loading ? (
              <span className="flex items-center">
                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Signing in...
              </span>
            ) : (
              'Sign in'
            )}
          </button>
        </form>
      </div>
    </div>
  );
}