import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, collection, getDocs, addDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { Event, Song } from '../types';
import { Calendar, MapPin, Music, DollarSign } from 'lucide-react';
import toast from 'react-hot-toast';
import { formatDateTime } from '../utils/dateFormat';
import { validateEmail, validatePhone } from '../utils/validation';
import { Modal } from '../components/Modal';
import { SongFilter } from '../components/SongFilter/SongFilter';
import { MessageForm } from '../components/MessageForm';
import { ShareEvent } from '../components/ShareEvent';
import { normalizeFirestoreData } from '../utils/songUtils';
import confetti from 'canvas-confetti';

export function EventDetail() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [event, setEvent] = useState<Event | null>(null);
  const [songs, setSongs] = useState<Song[]>([]);
  const [filteredSongs, setFilteredSongs] = useState<Song[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedSong, setSelectedSong] = useState<Song | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    requesterName: '',
    requesterContact: '',
    contactType: 'email' as 'email' | 'phone',
    note: ''
  });

  useEffect(() => {
    async function fetchEventAndSongs() {
      try {
        if (!id) return;
        
        // Fetch event details
        const eventDoc = await getDoc(doc(db, 'events', id));
        if (eventDoc.exists()) {
          setEvent({ id: eventDoc.id, ...eventDoc.data() } as Event);
        }

        // Fetch songs
        const songsRef = collection(db, 'songs');
        const songsSnapshot = await getDocs(songsRef);
        const songsList = songsSnapshot.docs
          .map(doc => {
            const data = doc.data();
            const normalizedData = normalizeFirestoreData(data);
            return {
              id: doc.id,
              ...normalizedData
            } as Song;
          })
          .filter(song => song.title && song.artist)
          .sort((a, b) => a.title.localeCompare(b.title));

        setSongs(songsList);
        setFilteredSongs(songsList);
      } catch (error) {
        console.error('Error fetching data:', error);
        toast.error('Failed to load event details');
      } finally {
        setLoading(false);
      }
    }

    fetchEventAndSongs();
  }, [id]);

  const handleRequestClick = (song: Song) => {
    setSelectedSong(song);
    setIsModalOpen(true);
  };

  const handleSubmit = async (e: React.FormEvent, withTip: boolean = false) => {
    e.preventDefault();
    if (!selectedSong || !event) return;

    const { requesterName, requesterContact, contactType, note } = formData;

    if (!requesterName.trim()) {
      toast.error('Please enter your name');
      return;
    }

    if (!requesterContact.trim()) {
      toast.error(`Please enter your ${contactType}`);
      return;
    }

    if (contactType === 'email' && !validateEmail(requesterContact)) {
      toast.error('Please enter a valid email address');
      return;
    }

    if (contactType === 'phone' && !validatePhone(requesterContact)) {
      toast.error('Please enter a valid phone number');
      return;
    }

    try {
      await addDoc(collection(db, 'requests'), {
        eventId: event.id,
        songId: selectedSong.id,
        requesterName: requesterName.trim(),
        requesterContact: requesterContact.trim(),
        contactType,
        note: note.trim(),
        timestamp: new Date().toISOString(),
        status: 'pending',
        type: 'song'
      });
      
      toast.success('Song request submitted successfully!');
      setFormData({
        requesterName: '',
        requesterContact: '',
        contactType: 'email',
        note: ''
      });
      setIsModalOpen(false);

      if (withTip) {
        navigate(`/events/${event.id}/tip`);
      }
    } catch (error) {
      console.error('Error submitting request:', error);
      toast.error('Failed to submit request');
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-[50vh]">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-600"></div>
      </div>
    );
  }

  if (!event) {
    return (
      <div className="text-center py-12">
        <h2 className="text-2xl font-bold text-gray-900">Event not found</h2>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto">
      <div className="bg-white rounded-lg shadow-md p-6 mb-8">
        <div className="flex flex-col sm:flex-row justify-between items-start gap-4">
          <div>
            <h1 className="text-2xl sm:text-3xl font-bold text-gray-900 mb-4">{event.name}</h1>
            <div className="space-y-3">
              <div className="flex items-center text-gray-600">
                <Calendar className="w-5 h-5 mr-2 flex-shrink-0" />
                <span className="text-sm sm:text-base">{formatDateTime(event.date)}</span>
              </div>
              <div className="flex items-center text-gray-600">
                <MapPin className="w-5 h-5 mr-2 flex-shrink-0" />
                <span className="text-sm sm:text-base">{event.location}</span>
              </div>
              {event.description && (
                <p className="text-sm sm:text-base text-gray-600 mt-4">{event.description}</p>
              )}
            </div>
          </div>
          <div className="w-full sm:w-auto space-y-2">
            <button
              onClick={() => navigate(`/events/${event.id}/tip`)}
              className="w-full sm:w-auto inline-flex items-center justify-center px-6 py-2.5 bg-green-600 text-white text-sm font-medium rounded-md hover:bg-green-700 transition-colors whitespace-nowrap"
              onMouseEnter={() => confetti()}
            >
              <DollarSign className="w-4 h-4 mr-2" />
              Send Brian a Tip
            </button>
            <div className="block w-full">
              <MessageForm eventId={event.id} />
            </div>
            <ShareEvent eventId={event.id} eventName={event.name} />
          </div>
        </div>
      </div>

      <div className="bg-white rounded-lg shadow-md p-6">
        <h2 className="text-xl font-bold text-gray-900 mb-6">Request a Song</h2>
        
        <SongFilter songs={songs} onFilteredSongsChange={setFilteredSongs} />

        <div className="mt-6 space-y-4">
          {filteredSongs.map((song) => (
            <button
              key={song.id}
              onClick={() => handleRequestClick(song)}
              className="w-full flex items-center p-4 border rounded-lg hover:bg-gray-50 transition-colors"
            >
              <Music className="w-5 h-5 text-indigo-600 mr-3 flex-shrink-0" />
              <div className="text-left">
                <h3 className="font-medium text-gray-900">{song.title}</h3>
                <p className="text-sm text-gray-600">{song.artist}</p>
              </div>
            </button>
          ))}

          {filteredSongs.length === 0 && (
            <div className="text-center py-12">
              <Music className="w-12 h-12 text-gray-400 mx-auto mb-4" />
              <h3 className="text-lg font-medium text-gray-900">No songs found</h3>
              <p className="text-gray-500">Try a different search term or genre</p>
            </div>
          )}
        </div>
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={`Request "${selectedSong?.title}"`}
      >
        <form onSubmit={(e) => handleSubmit(e)} className="space-y-6">
          <div className="space-y-4">
            <div>
              <label className="block text-lg font-medium text-gray-900 mb-2">Your Name</label>
              <input
                type="text"
                value={formData.requesterName}
                onChange={(e) => setFormData({ ...formData, requesterName: e.target.value })}
                className="w-full px-4 py-3 rounded-lg border-2 border-gray-300 focus:border-indigo-500 focus:ring focus:ring-indigo-200"
                placeholder="Enter your name"
              />
            </div>

            <div>
              <label className="block text-lg font-medium text-gray-900 mb-2">How should we contact you?</label>
              <div className="flex flex-col sm:flex-row gap-3">
                <select
                  value={formData.contactType}
                  onChange={(e) => setFormData({ ...formData, contactType: e.target.value as 'email' | 'phone' })}
                  className="sm:w-1/3 px-4 py-3 rounded-lg border-2 border-gray-300 focus:border-indigo-500 focus:ring focus:ring-indigo-200"
                >
                  <option value="email">Email</option>
                  <option value="phone">Phone</option>
                </select>
                <input
                  type={formData.contactType === 'email' ? 'email' : 'tel'}
                  value={formData.requesterContact}
                  onChange={(e) => setFormData({ ...formData, requesterContact: e.target.value })}
                  className="flex-1 px-4 py-3 rounded-lg border-2 border-gray-300 focus:border-indigo-500 focus:ring focus:ring-indigo-200"
                  placeholder={formData.contactType === 'email' ? 'Enter your email' : 'Enter your phone number'}
                />
              </div>
            </div>

            <div>
              <label className="block text-lg font-medium text-gray-900 mb-2">Special Note (Optional)</label>
              <textarea
                value={formData.note}
                onChange={(e) => setFormData({ ...formData, note: e.target.value })}
                rows={3}
                className="w-full px-4 py-3 rounded-lg border-2 border-gray-300 focus:border-indigo-500 focus:ring focus:ring-indigo-200"
                placeholder="Add a note to your request..."
              />
            </div>
          </div>

          <div className="flex flex-col sm:flex-row gap-3">
            <button
              type="button"
              onClick={() => setIsModalOpen(false)}
              className="w-full sm:w-auto px-6 py-3 border-2 border-gray-300 rounded-lg text-gray-700 font-medium hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="w-full sm:w-auto px-6 py-3 bg-indigo-600 text-white rounded-lg font-medium hover:bg-indigo-700"
            >
              Submit Request
            </button>
            <button
              type="button"
              onClick={(e) => handleSubmit(e, true)}
              className="w-full sm:w-auto px-6 py-3 bg-green-600 text-white rounded-lg font-medium hover:bg-green-700 flex items-center justify-center"
            >
              <DollarSign className="w-5 h-5 mr-2" />
              Request + Tip
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
}