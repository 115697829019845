import React, { useState } from 'react';
import { MessageCircle } from 'lucide-react';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { validateEmail, validatePhone } from '../utils/validation';
import toast from 'react-hot-toast';
import { Modal } from './Modal';

interface MessageFormProps {
  eventId: string;
}

export function MessageForm({ eventId }: MessageFormProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    requesterName: '',
    requesterContact: '',
    contactType: 'email' as 'email' | 'phone',
    note: ''
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const { requesterName, requesterContact, contactType, note } = formData;

    if (!requesterName.trim()) {
      toast.error('Please enter your name');
      return;
    }

    if (!requesterContact.trim()) {
      toast.error(`Please enter your ${contactType}`);
      return;
    }

    if (contactType === 'email' && !validateEmail(requesterContact)) {
      toast.error('Please enter a valid email address');
      return;
    }

    if (contactType === 'phone' && !validatePhone(requesterContact)) {
      toast.error('Please enter a valid phone number');
      return;
    }

    if (!note.trim()) {
      toast.error('Please enter your message');
      return;
    }

    try {
      // Use the requests collection with a special "Message" song
      await addDoc(collection(db, 'requests'), {
        eventId,
        songId: 'message', // This will match your "Send Brian a Message" song ID
        requesterName: requesterName.trim(),
        requesterContact: requesterContact.trim(),
        contactType,
        note: note.trim(),
        timestamp: new Date().toISOString(),
        status: 'pending',
        type: 'message'
      });
      
      toast.success('Message sent successfully!');
      setFormData({
        requesterName: '',
        requesterContact: '',
        contactType: 'email',
        note: ''
      });
      setIsModalOpen(false);
    } catch (error) {
      console.error('Error sending message:', error);
      toast.error('Failed to send message');
    }
  };

  return (
    <>
      <button
        onClick={() => setIsModalOpen(true)}
        className="w-full sm:w-auto inline-flex items-center justify-center px-6 py-2.5 bg-indigo-600 text-white text-sm font-medium rounded-md hover:bg-indigo-700 transition-colors whitespace-nowrap"
      >
        <MessageCircle className="w-4 h-4 mr-2" />
        Send a Message
      </button>

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="Send a Message"
      >
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Your Name</label>
            <input
              type="text"
              value={formData.requesterName}
              onChange={(e) => setFormData({ ...formData, requesterName: e.target.value })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              placeholder="Enter your name"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Contact Method</label>
            <div className="mt-1 flex flex-col sm:flex-row gap-2">
              <select
                value={formData.contactType}
                onChange={(e) => setFormData({ ...formData, contactType: e.target.value as 'email' | 'phone' })}
                className="rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              >
                <option value="email">Email</option>
                <option value="phone">Phone</option>
              </select>
              <input
                type={formData.contactType === 'email' ? 'email' : 'tel'}
                value={formData.requesterContact}
                onChange={(e) => setFormData({ ...formData, requesterContact: e.target.value })}
                className="flex-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                placeholder={formData.contactType === 'email' ? 'Enter your email' : 'Enter your phone number'}
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Message</label>
            <textarea
              value={formData.note}
              onChange={(e) => setFormData({ ...formData, note: e.target.value })}
              rows={4}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              placeholder="Please sing happy birthday to my friend..."
            />
          </div>

          <div className="flex justify-end space-x-3 mt-6">
            <button
              type="button"
              onClick={() => setIsModalOpen(false)}
              className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 bg-white hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700"
            >
              Send Message
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
}