import { Song } from '../types';

// Helper function to normalize a single genre
function normalizeGenre(genre: string): string {
  return genre
    .trim()
    .charAt(0)
    .toUpperCase() + genre.trim().slice(1).toLowerCase();
}

// Helper function to split genres string into array
function splitGenres(genresStr: string): string[] {
  if (!genresStr) return [];
  return genresStr
    .split(/[,;|/]/)
    .map(g => g.trim())
    .filter(Boolean)
    .map(normalizeGenre);
}

// Normalize Firestore data to handle the exact field names from your database
export function normalizeFirestoreData(data: any): Partial<Song> {
  // Handle case-sensitive field names and multiple possible genre fields
  const genres = data.genre || data.Genre || data.genres || data.Genres || '';
  
  // Convert genres to string if it's an array
  const genresStr = Array.isArray(genres) ? genres.join(', ') : genres;
  
  return {
    title: (data.title || data.Song || data.song || '').trim(),
    artist: (data.artist || data.Artist || '').trim(),
    genre: genresStr.trim()
  };
}

// Extract unique genres from songs
export function extractGenres(songs: Song[]): string[] {
  const genreSet = new Set<string>();
  
  songs.forEach(song => {
    if (song.genre) {
      // Split genres and add each one to the set
      splitGenres(song.genre).forEach(genre => {
        if (genre) genreSet.add(genre);
      });
    }
  });
  
  // Sort genres alphabetically
  return Array.from(genreSet).sort((a, b) => a.localeCompare(b));
}

// Filter songs by search query and genre
export function filterSongs(
  songs: Song[],
  searchQuery: string,
  selectedGenre: string | null
): Song[] {
  const normalizedQuery = searchQuery.toLowerCase().trim();
  const normalizedGenre = selectedGenre?.toLowerCase().trim() || null;
  
  return songs.filter(song => {
    // Search matching
    const matchesSearch = !normalizedQuery || 
      song.title.toLowerCase().includes(normalizedQuery) ||
      song.artist.toLowerCase().includes(normalizedQuery);

    // Genre matching - check if any of the song's genres match the selected genre
    const songGenres = splitGenres(song.genre || '').map(g => g.toLowerCase());
    const matchesGenre = !normalizedGenre || songGenres.includes(normalizedGenre);

    return matchesSearch && matchesGenre;
  });
}