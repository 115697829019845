import React from 'react';
import { Link } from 'react-router-dom';
import { Calendar } from 'lucide-react';

const BACKGROUND_IMAGE = 'https://firebasestorage.googleapis.com/v0/b/song-request-app-playground.firebasestorage.app/o/brian-wahlstrom.jpg?alt=media&token=b7580831-0ddb-4be7-a177-386b9ce519e6';

export function Home() {
  return (
    <div 
      className="flex flex-col items-center justify-center min-h-[calc(100vh-3.5rem)] sm:min-h-[calc(100vh-4rem)] relative px-4 py-8"
      style={{
        backgroundImage: `url(${BACKGROUND_IMAGE})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      }}
    >
      {/* Dark overlay */}
      <div className="absolute inset-0 bg-black bg-opacity-60"></div>
      
      {/* Content */}
      <div className="relative z-10 text-center w-full max-w-4xl px-4">
        <h1 className="font-knewave text-4xl sm:text-5xl md:text-6xl text-white mb-6 text-glow">
          Sing Me a Song!
        </h1>
        <p className="text-base sm:text-lg md:text-xl text-gray-200 mb-6 sm:mb-8 max-w-sm md:max-w-md mx-auto">
          Experience the magic of live piano entertainment. Request your favorite songs, send special messages, and connect with Brian's amazing performances.
        </p>
        <Link
          to="/events"
          className="inline-flex items-center px-5 sm:px-6 py-2.5 sm:py-3 border border-transparent text-base font-medium rounded-md text-gray-900 bg-white hover:bg-gray-100 transition-colors shadow-lg"
        >
          <Calendar className="w-5 h-5 mr-2" />
          View Events
        </Link>
      </div>
    </div>
  );
}