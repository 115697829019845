import React from 'react';
import { Song } from '../../types';
import { SearchInput } from './SearchInput';
import { GenreFilter } from './GenreFilter';
import { extractGenres, filterSongs } from '../../utils/songUtils';

interface SongFilterProps {
  songs: Song[];
  onFilteredSongsChange: (songs: Song[]) => void;
}

export function SongFilter({ songs, onFilteredSongsChange }: SongFilterProps) {
  const [searchQuery, setSearchQuery] = React.useState('');
  const [selectedGenre, setSelectedGenre] = React.useState<string | null>(null);

  // Extract unique genres from songs
  const genres = React.useMemo(() => extractGenres(songs), [songs]);

  // Filter songs based on search query and selected genre
  React.useEffect(() => {
    const filteredSongs = filterSongs(songs, searchQuery, selectedGenre);
    onFilteredSongsChange(filteredSongs);
  }, [searchQuery, selectedGenre, songs, onFilteredSongsChange]);

  return (
    <div className="space-y-4">
      <SearchInput 
        value={searchQuery}
        onChange={setSearchQuery}
        placeholder="Search by song title or artist..."
      />
      <div className="relative">
        <GenreFilter
          genres={genres}
          selectedGenre={selectedGenre}
          onGenreSelect={setSelectedGenre}
        />
      </div>
    </div>
  );
}