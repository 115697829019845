import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Music, Calendar, Home, Lock } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';

export function Navigation() {
  const location = useLocation();
  const { user } = useAuth();
  
  const isActive = (path: string) => location.pathname === path;
  
  return (
    <nav className="bg-white shadow-lg sticky top-0 z-50">
      <div className="container mx-auto px-4">
        <div className="flex items-center justify-between h-14 sm:h-16">
          <Link to="/" className="flex items-center space-x-2">
            <Music className="w-5 h-5 sm:w-6 sm:h-6 text-indigo-600" />
            <span className="font-bold text-lg sm:text-xl">Sing Me a Song</span>
          </Link>
          
          <div className="flex items-center space-x-1 sm:space-x-4">
            <Link
              to="/"
              className={`flex items-center space-x-1 px-2 sm:px-3 py-2 rounded-md text-sm font-medium transition-colors ${
                isActive('/') ? 'text-indigo-600 bg-indigo-50' : 'text-gray-700 hover:text-indigo-600'
              }`}
            >
              <Home className="w-4 h-4" />
              <span className="hidden sm:inline">Home</span>
            </Link>
            <Link
              to="/events"
              className={`flex items-center space-x-1 px-2 sm:px-3 py-2 rounded-md text-sm font-medium transition-colors ${
                isActive('/events') ? 'text-indigo-600 bg-indigo-50' : 'text-gray-700 hover:text-indigo-600'
              }`}
            >
              <Calendar className="w-4 h-4" />
              <span className="hidden sm:inline">Events</span>
            </Link>
            {user ? (
              <Link
                to="/admin"
                className={`flex items-center space-x-1 px-2 sm:px-3 py-2 rounded-md text-sm font-medium transition-colors ${
                  location.pathname.startsWith('/admin') ? 'text-indigo-600 bg-indigo-50' : 'text-gray-700 hover:text-indigo-600'
                }`}
              >
                <Lock className="w-4 h-4" />
                <span className="hidden sm:inline">Admin</span>
              </Link>
            ) : (
              <Link
                to="/admin/login"
                className={`flex items-center space-x-1 px-2 sm:px-3 py-2 rounded-md text-sm font-medium transition-colors ${
                  isActive('/admin/login') ? 'text-indigo-600 bg-indigo-50' : 'text-gray-700 hover:text-indigo-600'
                }`}
              >
                <Lock className="w-4 h-4" />
                <span className="hidden sm:inline">Login</span>
              </Link>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
}