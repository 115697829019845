import React from 'react';
import { Outlet } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { Navigation } from './Navigation';
import { Footer } from './Footer';

export function Layout() {
  return (
    <div className="min-h-screen flex flex-col bg-gray-50">
      <Navigation />
      <main className="container mx-auto px-4 py-4 md:py-8 flex-1">
        <Outlet />
      </main>
      <Footer />
      <Toaster 
        position="bottom-center"
        toastOptions={{
          duration: 3000,
          className: 'max-w-md mx-auto text-sm md:text-base',
          style: {
            background: '#363636',
            color: '#fff',
            maxWidth: 'calc(100vw - 32px)',
          },
        }}
      />
    </div>
  );
}