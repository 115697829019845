import React, { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Calendar, MapPin, Clock } from 'lucide-react';
import { collection, getDocs, query, where, orderBy } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { Event } from '../types';
import { format, parseISO, isSameDay, startOfDay } from 'date-fns';
import { EventCalendar } from '../components/EventCalendar';
import { ShareEvent } from '../components/ShareEvent';

export function EventList() {
  const [events, setEvents] = useState<Event[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [showCalendar, setShowCalendar] = useState(false);

  React.useEffect(() => {
    async function fetchEvents() {
      try {
        const eventsRef = collection(db, 'events');
        const today = startOfDay(new Date());
        const q = query(
          eventsRef,
          where('date', '>=', today.toISOString()),
          orderBy('date', 'asc')
        );
        const querySnapshot = await getDocs(q);
        const eventsList = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        } as Event));
        setEvents(eventsList);
      } catch (error) {
        console.error('Error fetching events:', error);
      } finally {
        setLoading(false);
      }
    }

    fetchEvents();
  }, []);

  const filteredEvents = useMemo(() => {
    if (!selectedDate) return events;
    return events.filter(event => 
      isSameDay(parseISO(event.date), selectedDate)
    );
  }, [events, selectedDate]);

  const groupedEvents = useMemo(() => {
    const groups: { [key: string]: Event[] } = {};
    
    filteredEvents.forEach(event => {
      const date = format(parseISO(event.date), 'yyyy-MM-dd');
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(event);
    });

    return Object.entries(groups)
      .sort(([dateA], [dateB]) => dateA.localeCompare(dateB))
      .map(([date, events]) => ({
        date,
        events
      }));
  }, [filteredEvents]);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-[50vh]">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-600"></div>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto px-4">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl sm:text-3xl font-bold text-gray-900">Upcoming Events</h1>
        <button
          onClick={() => setShowCalendar(!showCalendar)}
          className="flex items-center px-4 py-2 bg-white border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50"
        >
          <Calendar className="w-5 h-5 mr-2 text-gray-500" />
          Calendar View
        </button>
      </div>

      {showCalendar && (
        <div className="mb-6">
          <EventCalendar
            events={events}
            selectedDate={selectedDate}
            onSelect={(date) => setSelectedDate(date)}
          />
        </div>
      )}

      {groupedEvents.length === 0 ? (
        <div className="text-center py-12">
          <Calendar className="w-12 h-12 text-gray-400 mx-auto mb-4" />
          <h3 className="text-lg font-medium text-gray-900">No upcoming events</h3>
          <p className="text-gray-500">Check back later for new events.</p>
        </div>
      ) : (
        <div className="space-y-8">
          {groupedEvents.map(({ date, events }) => (
            <div key={date}>
              <h2 className="text-lg font-semibold text-gray-900 mb-4">
                {format(parseISO(date), 'EEEE, MMMM d, yyyy')}
              </h2>
              <div className="grid gap-4">
                {events.map((event) => (
                  <div
                    key={event.id}
                    className="bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200"
                  >
                    <div className="p-6">
                      <div className="flex justify-between items-start">
                        <Link
                          to={`/events/${event.id}`}
                          className="flex-1"
                        >
                          <h3 className="text-xl font-semibold text-gray-900 hover:text-indigo-600 transition-colors">
                            {event.name}
                          </h3>
                          <div className="mt-4 space-y-3">
                            <div className="flex items-center text-gray-600">
                              <Clock className="w-5 h-5 mr-2" />
                              <span>{format(parseISO(event.date), 'p')}</span>
                            </div>
                            <div className="flex items-center text-gray-600">
                              <MapPin className="w-5 h-5 mr-2" />
                              <span>{event.location}</span>
                            </div>
                            {event.description && (
                              <p className="text-gray-600 mt-2">{event.description}</p>
                            )}
                          </div>
                        </Link>
                        <ShareEvent eventId={event.id} eventName={event.name} />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}