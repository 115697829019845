import React from 'react';

export function Footer() {
  return (
    <footer className="footer py-4 mt-auto">
      <div className="container mx-auto px-4 text-center">
        <p className="text-sm">
          A Brian Wahlstrom Production • © {new Date().getFullYear()}
        </p>
      </div>
    </footer>
  );
}