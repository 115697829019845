import React from 'react';
import { DayPicker } from 'react-day-picker';
import { format, parseISO, isSameDay } from 'date-fns';
import { Event } from '../types';
import 'react-day-picker/dist/style.css';

interface EventCalendarProps {
  events: Event[];
  selectedDate: Date | undefined;
  onSelect: (date: Date | undefined) => void;
}

export function EventCalendar({ events, selectedDate, onSelect }: EventCalendarProps) {
  const eventDates = events.map(event => parseISO(event.date));

  const hasEvent = (day: Date) => {
    return eventDates.some(eventDate => isSameDay(day, eventDate));
  };

  return (
    <div className="p-4 bg-gray-900 rounded-lg shadow-xl border border-gray-800">
      <style>
        {`
          .rdp {
            --rdp-cell-size: 40px;
            --rdp-accent-color: #a855f7;
            --rdp-background-color: rgba(168, 85, 247, 0.2);
            margin: 0;
          }
          .rdp-day_selected:not(.rdp-day_disabled):not(.rdp-day_outside) {
            background-color: var(--rdp-accent-color);
            color: #fff;
          }
          .rdp-day_today {
            border-color: var(--rdp-accent-color) !important;
            color: var(--rdp-accent-color);
          }
          .rdp-day_has_event:not(.rdp-day_selected) {
            background-color: rgba(168, 85, 247, 0.1);
            color: #a855f7;
          }
          .rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
            background-color: rgba(168, 85, 247, 0.2) !important;
            color: #a855f7;
          }
          .rdp-head_cell {
            color: #9ca3af;
          }
          .rdp-day {
            color: #e5e7eb;
          }
          .rdp-day_outside {
            color: #4b5563;
          }
        `}
      </style>
      <DayPicker
        mode="single"
        selected={selectedDate}
        onSelect={onSelect}
        modifiers={{ hasEvent }}
        modifiersClassNames={{
          hasEvent: 'rdp-day_has_event'
        }}
        showOutsideDays
        fixedWeeks
      />
    </div>
  );
}