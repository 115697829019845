import { format, parseISO, isValid } from 'date-fns';

export function formatDateTime(dateString: string | null | undefined): string {
  if (!dateString) return 'No date available';
  
  try {
    const date = parseISO(dateString);
    if (!isValid(date)) {
      return 'Invalid date';
    }
    return format(date, 'PPP p');
  } catch (error) {
    console.error('Error formatting date:', error);
    return 'Invalid date';
  }
}

export function formatDateTimeForInput(dateString: string | null | undefined): string {
  if (!dateString) return '';
  
  try {
    const date = parseISO(dateString);
    if (!isValid(date)) {
      return '';
    }
    return format(date, "yyyy-MM-dd'T'HH:mm");
  } catch (error) {
    console.error('Error formatting date for input:', error);
    return '';
  }
}

export function ensureValidDate(dateString: string): string {
  try {
    const date = new Date(dateString);
    if (!isValid(date)) {
      throw new Error('Invalid date');
    }
    return date.toISOString();
  } catch (error) {
    console.error('Error validating date:', error);
    throw new Error('Invalid date format');
  }
}

export function formatEventDate(dateString: string): string {
  try {
    const date = parseISO(dateString);
    if (!isValid(date)) {
      return 'Invalid date';
    }
    return format(date, 'EEEE, MMMM d, yyyy');
  } catch (error) {
    console.error('Error formatting event date:', error);
    return 'Invalid date';
  }
}

export function formatEventTime(dateString: string): string {
  try {
    const date = parseISO(dateString);
    if (!isValid(date)) {
      return 'Invalid time';
    }
    return format(date, 'p');
  } catch (error) {
    console.error('Error formatting event time:', error);
    return 'Invalid time';
  }
}