import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyDNzuZkWIc7HUDHjrFUWZMM199zyPrA7Rk",
  authDomain: "song-request-app-playground.firebaseapp.com",
  projectId: "song-request-app-playground",
  storageBucket: "song-request-app-playground.firebasestorage.app",
  messagingSenderId: "917283090904",
  appId: "1:917283090904:web:a4a83493195eae70c382ad",
  measurementId: "G-4M1EXBNGJ7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore, Auth, and Storage
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

// Enable persistence
setPersistence(auth, browserLocalPersistence).catch(console.error);

export { db, auth, storage };