import React, { useState } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import { Share2, Copy, Check } from 'lucide-react';
import { Modal } from './Modal';
import toast from 'react-hot-toast';

interface ShareEventProps {
  eventId: string;
  eventName: string;
}

export function ShareEvent({ eventId, eventName }: ShareEventProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [copied, setCopied] = useState(false);

  const eventUrl = `${window.location.origin}/events/${eventId}`;

  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(eventUrl);
      setCopied(true);
      toast.success('Link copied to clipboard!');
      setTimeout(() => setCopied(false), 2000);
    } catch (error) {
      console.error('Failed to copy:', error);
      toast.error('Failed to copy link');
    }
  };

  return (
    <>
      <button
        onClick={() => setIsModalOpen(true)}
        className="p-2 text-gray-600 hover:text-indigo-600 hover:bg-indigo-50 rounded-full transition-colors"
        title="Share Event"
      >
        <Share2 className="w-5 h-5" />
      </button>

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={`Share ${eventName}`}
      >
        <div className="space-y-6">
          <div className="flex justify-center">
            <div className="p-4 bg-white rounded-lg">
              <QRCodeSVG
                value={eventUrl}
                size={200}
                level="H"
                includeMargin
                className="w-full h-full"
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Event Link
            </label>
            <div className="flex mt-1">
              <input
                type="text"
                readOnly
                value={eventUrl}
                className="flex-1 block w-full rounded-l-md border-gray-300 bg-gray-50 text-gray-900 focus:border-indigo-500 focus:ring-indigo-500"
              />
              <button
                onClick={handleCopyLink}
                className="inline-flex items-center px-4 py-2 border border-l-0 border-gray-300 rounded-r-md bg-gray-50 text-gray-700 hover:text-indigo-600"
              >
                {copied ? (
                  <Check className="w-5 h-5 text-green-600" />
                ) : (
                  <Copy className="w-5 h-5" />
                )}
              </button>
            </div>
          </div>

          <p className="text-sm text-gray-600 text-center">
            Share this QR code or link with your guests so they can easily access the event page
            and submit song requests!
          </p>
        </div>
      </Modal>
    </>
  );
}