// Email validation
export function validateEmail(email: string): boolean {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

// Phone validation
export function validatePhone(phone: string): boolean {
  const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  return phoneRegex.test(phone);
}

// Format phone number
export function formatPhone(phone: string): string {
  const cleaned = phone.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return phone;
}

// Event validation
export function validateEvent(event: {
  name: string;
  date: string;
  location: string;
}): { isValid: boolean; errors: string[] } {
  const errors: string[] = [];

  if (!event.name?.trim()) {
    errors.push('Event name is required');
  }

  if (!event.date) {
    errors.push('Event date is required');
  } else {
    const eventDate = new Date(event.date);
    if (isNaN(eventDate.getTime())) {
      errors.push('Invalid event date');
    }
  }

  if (!event.location?.trim()) {
    errors.push('Event location is required');
  }

  return {
    isValid: errors.length === 0,
    errors
  };
}

// Song validation
export function validateSong(song: {
  title: string;
  artist: string;
}): { isValid: boolean; errors: string[] } {
  const errors: string[] = [];

  if (!song.title?.trim()) {
    errors.push('Song title is required');
  }

  if (!song.artist?.trim()) {
    errors.push('Artist name is required');
  }

  return {
    isValid: errors.length === 0,
    errors
  };
}