import React from 'react';
import { collection, getDocs, updateDoc, doc } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { Request } from '../../types';
import { Music, X, Clock, MessageCircle, Check } from 'lucide-react';
import toast from 'react-hot-toast';
import { useAuth } from '../../contexts/AuthContext';
import { formatDateTime } from '../../utils/dateFormat';
import { normalizeFirestoreData } from '../../utils/songUtils';

interface ExtendedRequest extends Request {
  songTitle?: string;
  eventName?: string;
}

export function AdminRequests() {
  const { user } = useAuth();
  const [requests, setRequests] = React.useState<ExtendedRequest[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [filter, setFilter] = React.useState<'all' | 'pending' | 'played' | 'rejected' | 'message'>('all');

  const fetchRequests = async () => {
    if (!user) {
      setLoading(false);
      return;
    }

    try {
      const requestsRef = collection(db, 'requests');
      const requestsSnapshot = await getDocs(requestsRef);
      
      // Get songs and events data
      const [songsSnapshot, eventsSnapshot] = await Promise.all([
        getDocs(collection(db, 'songs')),
        getDocs(collection(db, 'events'))
      ]);

      // Create maps for songs and events with normalized data
      const songs = new Map(songsSnapshot.docs.map(doc => {
        const data = normalizeFirestoreData(doc.data());
        return [doc.id, { title: data.title }];
      }));

      const events = new Map(eventsSnapshot.docs.map(doc => [
        doc.id,
        { name: doc.data().name || 'Unknown Event' }
      ]));

      // Process requests
      const requestsList = requestsSnapshot.docs.map(doc => {
        const data = doc.data();
        const isMessage = data.songId === 'message';
        return {
          id: doc.id,
          ...data,
          songTitle: isMessage ? 'Message' : (songs.get(data.songId)?.title || 'Unknown Song'),
          eventName: events.get(data.eventId)?.name || 'Unknown Event',
          type: isMessage ? 'message' : 'song'
        } as ExtendedRequest;
      });

      // Sort by timestamp descending
      requestsList.sort((a, b) => 
        new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
      );

      setRequests(requestsList);
    } catch (error) {
      console.error('Error fetching requests:', error);
      toast.error('Failed to load requests');
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchRequests();
  }, [user]);

  const handleUpdateStatus = async (requestId: string, newStatus: 'played' | 'rejected' | 'read' | 'responded') => {
    if (!user) {
      toast.error('You must be logged in');
      return;
    }

    try {
      await updateDoc(doc(db, 'requests', requestId), {
        status: newStatus,
        updatedAt: new Date().toISOString(),
        updatedBy: user.uid
      });
      
      toast.success('Status updated successfully');
      fetchRequests();
    } catch (error) {
      console.error('Error updating status:', error);
      toast.error('Failed to update status');
    }
  };

  const filteredRequests = requests.filter(request => {
    if (filter === 'all') return true;
    if (filter === 'message') return request.type === 'message';
    return request.status === filter && request.type === 'song';
  });

  // Group requests by event
  const groupedRequests = React.useMemo(() => {
    const groups: { [key: string]: ExtendedRequest[] } = {};
    filteredRequests.forEach(request => {
      const eventName = request.eventName || 'Unknown Event';
      if (!groups[eventName]) {
        groups[eventName] = [];
      }
      groups[eventName].push(request);
    });
    return groups;
  }, [filteredRequests]);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-[50vh]">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-600"></div>
      </div>
    );
  }

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-gray-900">Requests & Messages</h1>
        <div className="flex space-x-2">
          <select
            value={filter}
            onChange={(e) => setFilter(e.target.value as any)}
            className="rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
          >
            <option value="all">All</option>
            <option value="pending">Pending Songs</option>
            <option value="played">Played Songs</option>
            <option value="rejected">Rejected Songs</option>
            <option value="message">Messages</option>
          </select>
        </div>
      </div>

      <div className="space-y-8">
        {Object.entries(groupedRequests).map(([eventName, eventRequests]) => (
          <div key={eventName} className="mb-8">
            <h2 className="text-lg font-semibold text-gray-900 mb-4">
              {eventName}
              <span className="ml-2 text-sm font-normal text-gray-500">
                ({eventRequests.length} {eventRequests.length === 1 ? 'request' : 'requests'})
              </span>
            </h2>
            <div className="space-y-4">
              {eventRequests.map((request) => (
                <div key={request.id} className="bg-white p-6 rounded-lg shadow-md">
                  <div className="flex justify-between items-start">
                    <div>
                      <h3 className="font-medium text-gray-900 flex items-center">
                        {request.type === 'message' ? (
                          <MessageCircle className="w-5 h-5 text-indigo-600 mr-2" />
                        ) : (
                          <Music className="w-5 h-5 text-indigo-600 mr-2" />
                        )}
                        {request.songTitle}
                      </h3>
                      {request.note && (
                        <p className="text-sm text-gray-600 mt-2">
                          {request.type === 'message' ? request.note : `Note: ${request.note}`}
                        </p>
                      )}
                      <p className="text-sm text-gray-500">
                        From: {request.requesterName}
                      </p>
                      <p className="text-sm text-gray-500">
                        Contact: {request.requesterContact} ({request.contactType})
                      </p>
                      <p className="text-sm text-gray-500 mt-2">
                        Received: {formatDateTime(request.timestamp)}
                      </p>
                    </div>
                    <div className="flex items-center space-x-2">
                      {request.status === 'pending' && (
                        request.type === 'message' ? (
                          <>
                            <button
                              onClick={() => handleUpdateStatus(request.id, 'responded')}
                              className="p-2 text-green-600 hover:bg-green-50 rounded-full"
                              title="Mark as Responded"
                            >
                              <Check className="w-5 h-5" />
                            </button>
                            <button
                              onClick={() => handleUpdateStatus(request.id, 'read')}
                              className="p-2 text-blue-600 hover:bg-blue-50 rounded-full"
                              title="Mark as Read"
                            >
                              <MessageCircle className="w-5 h-5" />
                            </button>
                          </>
                        ) : (
                          <>
                            <button
                              onClick={() => handleUpdateStatus(request.id, 'played')}
                              className="p-2 text-green-600 hover:bg-green-50 rounded-full"
                              title="Mark as Played"
                            >
                              <Music className="w-5 h-5" />
                            </button>
                            <button
                              onClick={() => handleUpdateStatus(request.id, 'rejected')}
                              className="p-2 text-red-600 hover:bg-red-50 rounded-full"
                              title="Reject Request"
                            >
                              <X className="w-5 h-5" />
                            </button>
                          </>
                        )
                      )}
                      <span className={`inline-flex items-center px-3 py-1 rounded-full text-sm ${
                        request.status === 'pending' ? 'bg-yellow-100 text-yellow-800' :
                        request.status === 'played' || request.status === 'responded' ? 'bg-green-100 text-green-800' :
                        request.status === 'read' ? 'bg-blue-100 text-blue-800' :
                        'bg-red-100 text-red-800'
                      }`}>
                        {request.status.charAt(0).toUpperCase() + request.status.slice(1)}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}

        {Object.keys(groupedRequests).length === 0 && (
          <div className="text-center py-12">
            <Clock className="w-12 h-12 text-gray-400 mx-auto mb-4" />
            <h3 className="text-lg font-medium text-gray-900">No requests found</h3>
            <p className="text-gray-500">There are no requests matching your filter.</p>
          </div>
        )}
      </div>
    </div>
  );
}